header{
    height: 100vh;
    padding-top: 10rem;
    padding-bottom: 0;
    overflow: hidden;
    position: relative;
    /* display: grid;
    grid-template-columns: 3fr; */
    
    background-color: var(--color-header-bg);
}
 

.header__container{
    text-align: left;
    height: 100%;
    position: relative;
}

.header__description{
    padding: 10%;
    padding-bottom: 0;
}


h1{
    font-family: 'Permanent Marker', cursive;
    font-size: 4rem;
}

h1, h4{
    margin-bottom: 2rem;
}

.header__description h3{
    margin-bottom: 0.5rem;
    width: 80%;
}

.header__socialMenu{
    margin-top: 5rem;
    margin-left: 35%;
    display: flex;
    gap: 1rem;
}

.text-highlight{
    color: var(--color-filler-tertiary);
    font-weight: bold;
}

/* ----------- PICTURE ----------- */
 .portrait{
    background: linear-gradient(#f3d2c1, transparent);
    width: 24rem;
    height: auto;
    position: absolute;
    right: 15%;
    bottom: 0;
    transform: translateX(50%);
    border-radius: 30% 70% 63% 37% / 49% 30% 70% 51% ;
    padding: 1.5rem;
    padding-bottom: 0;
    object-fit: cover;
}



/* ----------- SCROLL ----------- */
.scroll__down{
    position: absolute;
    right: 20%;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 800;
    font-size: 0.9rem;
    text-transform: uppercase;
    animation: move ease-in-out 3s infinite;
}

#smooth-a{
    scroll-behavior: smooth;
}


@keyframes move {
    0%, 100% {
      transform: translateY(-10px) rotate(90deg);;
    }
    50% {
      transform: translateY(5px) rotate(90deg);;
      
    }
  }

/* ----------- RESPONSIVE ----------- */
/* ----------- MEDIUM DEVICES STYLE ----------- */
@media screen and (max-width: 1600px) {
   
    .portrait{
        top: 40%;
        right: 15%;  
        width: 19rem;
        height: 20rem;
    }
}

@media screen and (max-width: 1368px) {
    .portrait{
        top: 40%;
        right: 15%;  
        width: 19rem;
        height: 20rem;
        margin-top: 66px;
    }
}

@media screen and (max-width: 1024px) {
    header{
        height: 70vh;
        padding-top: 5%;
        display: block;
    }

    .scroll__down{
        display: none;
    }

    .portrait{
        top: 30%;
        right: 15%;  
        overflow: hidden;
    }

    h1{
        font-size: 3rem;
    }

    .header__socialMenu{
        margin-top: 5rem;
        margin-left: 0%;
        display: flex;
        gap: 1rem;
    }
}


/* ----------- SMALL DEVICES STYLE ----------- */
@media screen and (max-width: 800px) {
    .portrait{
        top: 45%;
        right: 20%;  
        width: 12rem;
        height: 15rem;
        overflow: hidden;
    }
}

@media screen and (max-width: 730px) {
    header{
        height: 100vh;
        padding-top: 5%;
        display: block;
    }

    .scroll__down{
        display: none;
    }

    .portrait{
        top: 45%;
        right: 50%;  
        width: 15rem;
        height: 18rem;
        overflow: hidden;
    }

    .header__description h1{
        font-size: 2rem;
        line-height: 1;
    }

    .info_add{
        display: none;
    }

    .header__socialMenu{
        margin-top: 2rem;
        margin-left: 10%;
        display: flex;
        gap: 1rem;
    }

    .header__description h2{
        display: none;
    }

}

@media screen and (max-width: 500px) {
    .portrait{
        visibility: hidden;
    }
}