.shapes{
    background-color: var(--color-testimonial-bg);
    --mask:
    radial-gradient(2.06rem at 50% 2.80rem,#000 99%,#0000 101%) calc(50% - 2rem) 0/4rem 51% repeat-x,
    radial-gradient(2.06rem at 50% -1.8rem,#0000 99%,#000 101%) 50% 1rem/4rem calc(51% - 1rem) repeat-x,
    radial-gradient(2.06rem at 50% calc(100% - 2.80rem),#000 99%,#0000 101%) calc(50% - 2rem) 100%/4rem 51% repeat-x,
    radial-gradient(2.06rem at 50% calc(100% + 1.80rem),#0000 99%,#000 101%) 50% calc(100% - 1rem)/4rem calc(51% - 1rem) repeat-x;
    -webkit-mask: var(--mask);
    mask: var(--mask);
    padding-bottom: 2rem;
}


#testimonal h2{
    padding-top: 3rem;
    font-size: 3rem;
    margin-bottom: 3rem;
}


.container.testimonials__container{
    width: 30%;
    padding-bottom: 1.8rem;
}

.client__image{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6rem;
    aspect-ratio: 1/1;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.testimonial {
    background: var(--color-testimonial-card-bg);
    text-align: center;
    padding: 2rem;
    user-select: none;
    margin-bottom: 1rem;
    border-radius: var(--border-radius);
}

.client__review{
    color: var(--color-card-text-variant);
    font-weight: 500;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
}


.client__name{
    font-weight: bold;
    margin-bottom: 0.1rem;
}

.client__title{
    font-style: italic;
}

.swiper-pagination-clickable .swiper-pagination-bullet-active{
    background: var(--color-filler-tertiary);
}

span.swiper-pagination-bullet{
    background: var(--color-filler-tertiary);
}

/* ----------- RESPONSIVE ----------- */
/* ----------- MEDIUM DEVICES STYLE ----------- */
@media screen and (max-width: 1024px) {
    #testimonal h2{
        margin-bottom: 2rem;
    }

    .container.testimonials__container{
        width: 75%;
    }
}

/* ----------- SMALL DEVICES STYLE ----------- */
@media screen and (max-width: 600px) {
    #testimonal h2{
        font-size: 2rem;
        background-size: 19rem 0.9rem;
        margin-bottom: 2rem;
    }

    .container.testimonials__container{
        width: var(--container--width-small);
    }

    .client__review{
        width: var(--container--width-small);
    }
}