#about h2{
    padding-top: 3rem;
    font-size: 3rem;
    margin-bottom: 2rem;
}

#about{
    background-color: var(--color-about-bg);
    padding-bottom: 5rem;
}

.about__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about__menu{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    padding-bottom: 2rem;
}

.about__menuCard{
    background-color: var(--color-about-card);
    padding: 1rem;
    margin: 0 0 10px 10px;
    text-align: center;
    transition: var(--transition);
    border-radius: var(--border-radius);
}

.about__icon{
    color: var(--color-filler-tertiary);
    font-size: 1.2rem;
    padding-top: 0.5rem;
}

#svg-hola{
    fill: var(--color-filler-tertiary);
}

.about__menu article h5{
    font-weight: bold;
    font-size: 1.1rem;
    padding: 0.5rem;
}

.about__menu article small{
    font-size: 0.7rem;
}

.about__icon-more{
    font-size: 0.55rem;
    transform: translateY(1px);
}

.about__menuCard:hover{
    background-color: var(--color-about-card-hover);
    cursor: pointer;
    transform: translateY(-5px);
}


.active__menuCard{
    border: 3px solid var(--color-filler-secondary);
}

/* .active__menuCard:after{
    content:'';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -15px;
    margin: 0 auto;
    width: 0;
    height: 5;
    border-top: 15px solid var(--color-about-card);
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
} 

.active__menuCard:hover::after{
    border-top: 15px solid var(--color-about-card-hover);
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
}  */

.about__content{
    width: 70%;
    height: 12rem;

    display: flex;
    justify-content: center;
}

.about__content p{
    text-align: center;
}

.about__content a{
    font-weight: bold;
    color: var(--color-filler-tertiary);
}

.p-align-left{
    text-align: left;
    color: var(--color-text-variant);
    font-size: 0.9rem;
}

/* ----------- CARDS DISPLAY ON/OFF ----------- */
.about__contentCard {
  display: none;
}

.active__contentCard {
  display: block;
}

/* ----------- RESPONSIVE ----------- */
/* ----------- MEDIUM DEVICES STYLE ----------- */
@media screen and (max-width: 1024px) {
    #about h2{
        margin-bottom: 0rem;
    }

    .about__menu{
        display: grid;
        border: 1px solid transparent;
        padding-top: 0.5rem;
        text-align: center;
        transition: var(--transition);

        padding-bottom: 3rem;
    }

    .about__menu article .about__icon{
        display: none;
    }
    .about__menu article small{
        display: none;
    }

    .about__menu article h5{
        padding: 0;
        margin: 0;
    }

    .about__menuCard{
        padding: 0.5rem;
    }

    .active__menuCard{
        transform: none;
    }

    .about__content{
        padding: 0 2rem 2rem 2rem;
        height: auto;
        width: auto;
    }

}

/* ----------- SMALL DEVICES STYLE ----------- */
@media screen and (max-width: 600px) {
    #about h2{
        margin: 0;
        padding: 0.2rem;
    }

    .about__menu{
        display: flex;
        flex-direction: column;
        border: 1px solid transparent;
        padding: 0.5rem;
        text-align: center;
        transition: var(--transition);
    }

    .about__menu article .about__icon{
        display: none;
    }
    .about__menu article small{
        display: none;
    }

    .about__menu article h5{
        padding: 0;
        margin: 0;
    }

    .about__menuCard{
        padding: 0.5rem;
    }

    .active__menuCard{
        background-color: var(--color-filler-secondary);
    }

     .about__content{
        padding: 0 2rem 2rem 2rem;
        height: auto;
        width: auto;
    }

    .about__content p{
        text-align: left;
    }
}