.contact__socialMenu{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
}

.contact__social{
    width: fit-content;
    display: inline-block;
    color: var(--color-text-btn);
    padding: 0.75rem 1.2rem;
    cursor: pointer;

    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

    background-color: var(--color-filler-tertiary);
    transition: var(--transition);
    font-weight: 800;
    font-size: 2rem;
}


.contact__social-buttonInsta:hover{
    color: white;
    background-color: var(--color-insta);
    border-radius: 10% 10% 10% 10% / 0% 0% 0% 0%;
    margin: 0 0.5rem 0 0.5rem;
}

.contact__social-buttonWa:hover{
    color: white;
    background-color: var(--color-wa);
    border-radius: 10% 10% 10% 10% / 0% 0% 0% 0%;
    margin: 0 0.5rem 0 0.5rem;
}

.contact__social-buttonLinkedin:hover{
    color: white;
    background-color: var(--color-linkedin);
    border-radius: 10% 10% 10% 10% / 0% 0% 0% 0%;
    margin:0 0.5rem 0 0.5rem;
}