#contact{
    background-color: var(--color-contact-bg);
    padding-bottom: 10rem;
/*     height: 100vh; */
}

#contact h2{
    padding-top: 3rem;
    font-size: 3rem;
    margin-bottom: 3rem;
}

.container.contact__container{
    padding-top: 3rem;
    width: 50%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
}

/* ----------- FORM ----------- */
form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea{
    width: 100%;
    margin-bottom: 1rem;
    background: transparent;
    border-bottom: 1px solid var(--color-filler-tertiary);
    resize: none;
    font-family: 'Poppins', sans-serif;
}

.contact-button{
    border-radius: 0.2rem;
    width: max-content;
    display: inline-block;
    color: var(--color-text-btn);
    background-color: var(--color-contact-btn);
    padding: 0.75rem 1.2rem;
    cursor: pointer;

    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

    transition: var(--transition);
    font-weight: 800;
    font-size: 1.1rem;
}

.contact-button:hover{
    background-color: var(--color-text-btn);
    color: var(--color-contact-btn);
}


/* --------- DESC ------------*/
.contact__desc p{
    text-align: right;
}


/* ----------- RESPONSIVE ----------- */
/* ----------- MEDIUM DEVICES STYLE ----------- */
@media screen and (max-width: 1024px) {
    #contact h2{
        margin-bottom: 2rem;
    }

    .container.contact__container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }

}

/* ----------- SMALL DEVICES STYLE ----------- */
@media screen and (max-width: 600px) {
    .container.contact__container{
        width: var(--container--width-small);
    }
}