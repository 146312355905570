/*Footer with waves*/
.footer {
  position: relative;
  width: 100%;
  background: var(--color-wave);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.menu__item {
  list-style: none;
}

.menu__item:hover {
  transform: translateY(-5px);
}

.menu__link {
  font-size: 1.2rem;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
  text-decoration: none;
  opacity: 0.;
  font-weight: 300;
}

.menu__link:hover{
  opacity: 1;
}

.footer p {
  margin: 0 10px;
  font-size: 1rem;
  font-weight: 300;
}

.legal{
  font-family: 'Poppins', sans-serif;
  color: var(--color-text-primary);
  text-transform: none;
  font-weight: 300;
}

.wave {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url('../../img/wave2.png');
  background-size: 1000px 100px;
}

.wave#wave1 {
  z-index: 1000;
  opacity: 1;
  bottom: 0;
  animation: animateWaves 12s linear infinite;
}

.wave#wave2 {
  z-index: 999;
  opacity: 0.5;
  bottom: 10px;
  animation: animate 12s linear infinite !important;
}

.wave#wave3 {
  z-index: 1000;
  opacity: 0.2;
  bottom: 15px;
  animation: animateWaves 8s linear infinite;
}

.wave#wave4 {
  z-index: 999;
  opacity: 0.7;
  bottom: 20px;
  animation: animate 8s linear infinite;
}

@keyframes animateWaves {
  0% {
    background-position-x: 1000px;
  }
  100% {
    background-positon-x: 0px;
  }
}

@keyframes animate {
  0% {
    background-position-x: -1000px;
  }
  100% {
    background-positon-x: 0px;
  }
}


/* ----------- RESPONSIVE ----------- */
/* ----------- MEDIUM DEVICES STYLE ----------- */
@media screen and (max-width: 1024px) {
  .wave{
    display: none;
  }

  .footer{
    display: none;
  }
}

/* ----------- SMALL DEVICES STYLE ----------- */
@media screen and (max-width: 600px) {
  .wave{
    display: none;
  }
  .footer{
    display: none;
  }
}