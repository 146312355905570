#services h2{
    padding-top: 3rem;
    font-size: 3rem;
    margin-bottom: 1rem;
}

#services h4{
    text-align: center;
}

#services{
    padding-bottom: 5rem;
    background-color: var(--color-services-bg);
}

.services__container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}


.service {
    background: var(--color-services-card-bg);
    height: fit-content;
    transition: var(--transition);
    box-shadow: 0 0.1rem 0.5rem rgba(0,0,0,0.1);

    border-radius: var(--border-radius);
}

.service:hover{
    transform: translateY(-1rem);
}

.service__head{
    background: var(--color-services-card-bg);
    padding: 1rem;
    padding-top: 1.8rem;
    border-radius: var(--border-radius);
}

.service__head h3{
    color: var(--color-card-healine-primary);
    font-size: 1.3rem;
    text-align: center;
    font-weight: bold;
}

.service__list {
    padding: 2rem;
    padding-top: 1rem;
}

.service__list li{
    display: flex;
    margin-bottom: 1.4rem;
}

.service__list p{
    font-size: 1rem;
    color: var(--color-card-text-primary);
}

.service__list li a{
    font-weight: 900;
    text-decoration: underline;
    color: var(--color-filler-tertiary);
}

.service strong{
    color: var(--color-filler-tertiary);
}

.service__list__icon{
    color: var(--color-filler-secondary);
    height: 1rem;
}


/* ----------- RESPONSIVE ----------- */
/* ----------- MEDIUM DEVICES STYLE ----------- */
@media screen and (max-width: 1400px) {

    .services__container{
        display: grid;
        grid-template-columns:  0.8fr 1.4fr 0.8fr;
        gap: 1rem;
    }
}

@media screen and (max-width: 1024px) {
    #services h2{
        margin-bottom: 2rem;
    }
    
    .services__container{
        grid-template-columns: repeat(1, 1fr);
        gap: 2rem;
    }

    .service {
        height: auto;
    }
    
    .service:hover{
        transform: none;
    }
}

/* ----------- SMALL DEVICES STYLE ----------- */
@media screen and (max-width: 600px) {

}

@media screen and (max-width: 600px) {
    #services h2{
        margin-bottom: 2rem;
    }
    
    .services__container{
        grid-template-columns: repeat(1, 1fr);
        gap: 1.1rem;
    }

    .service, .services {
        height: auto;
    }

    .service:hover{
        transform: none;
    }
}