.button__social{
    width: fit-content;
    display: inline-block;
    color: var(--color-text-btn);
    padding: 0.75rem 1.2rem;
    cursor: pointer;

    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

    background-color: var(--color-filler-tertiary);
    transition: var(--transition);
    font-weight: 800;
    font-size: 2rem;
}

.button__insta:hover{
    color: white;
    background-color: var(--color-insta);
    border-radius: 10%;
    transform: translateY(-10px);
    margin-right: 10px;
    margin-left: -10px;
}

.button__linkedin:hover{
    color: white;
    background-color: var(--color-linkedin);
    border-radius: 10%;
    transform: translateY(-10px);
    margin-left: 10px;
}


/* ----------- SMALL DEVICES STYLE ----------- */
@media screen and (max-width: 600px) {
    .header__socialMenu{
        align-items: center;
        justify-content: center;
    }
}