/*  LEFT SIDE */
nav{
    background: var(--color-nav-bg);
    width: max-content;
    padding: 1.7rem 0.7rem;
    z-index: 2;
    position: fixed;

    left: 10%;
    bottom: 50%;
    transform: translate(-150%, 50%);

    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    border-radius: 0.4rem;
    backdrop-filter: blur(10px);
}

nav a{
    background: transparent;
    padding: 0.9rem;
    border-radius: 0.4rem;
    display: flex;
    font-size: 1.5rem;
    transition: 0.5s;
    color: var(--color-nav-icons);
}

nav a:hover{
    background: var(--color-nav-hover);
    color: var(--color-nav-text-hover);
}

nav a.active{
    background: var(--color-nav-active);
    transform: translateX(35px);
    color: var(--color-text-btn);
}


/* ----------- RESPONSIVE ----------- */
/* ----------- MEDIUM DEVICES STYLE ----------- */
@media screen and (max-width: 1024px) {
    nav{
        padding: 0.7rem 1.7rem;
        left: 50%;
        transform: translateX(-50%);
        bottom: 2rem;
        display: flex;
        flex-direction: row;
    }

    nav a.active{
        transform: translateY(-35px);
    }

}

/* ----------- SMALL DEVICES STYLE ----------- */
@media screen and (max-width: 600px) {

}